<template>
  <div class="supervisor-table">
    <iq-title class-name="mobileHeader">На парковке:</iq-title>
    <SupervisorTableBody
      :table-data="statisticList"
      :table-fields-desktop="supervisorTableFields"
      :is-loading="getIsLoading"
    >
      <template #default="slotProps">
        <SupervisorTableCell
          :row="slotProps.data.row"
          :prop-key="slotProps.data.prop"
        />
      </template>
    </SupervisorTableBody>
  </div>
</template>

<script>
import {
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  GET_SUPERVISOR_STATISTIC_TABLE,
} from '@/store/actions'
import { mapGetters } from 'vuex'
import { supervisorTableFields } from '@/views/supervisor/data/supervisorData'
import IqTitle from '@/UI/typography/IqTitle'
import SupervisorTableBody from '@/views/supervisor/components/supervisor-statistics/components/SupervisorTableBody'
import SupervisorTableCell from '@/views/supervisor/components/supervisor-statistics/components/SupervisorTableCell'

export default {
  name: 'SupervisorStatistics',
  components: { SupervisorTableCell, SupervisorTableBody, IqTitle },
  data() {
    return {
      supervisorTableFields,
    }
  },
  computed: {
    ...mapGetters({
      statisticList: GET_SUPERVISOR_STATISTIC_TABLE,
      getIsLoading: GET_LOADINGS_SUPERVISOR_STATISTIC,
    }),
  },
}
</script>

<style lang="sass">
.supervisor-table
  padding: 20px 0

  &__title
    margin-bottom: 16px
    font-size: 20px
    text-transform: uppercase

  .total_count,
  .most_waiting_text,
  .backsiders_count,
  .backsiders_waiting_text,
  .boardsiders_count,
  .boardsiders_waiting_text
    text-align: center
    & .table-control-label__label
      text-align: center

  .el-table th.el-table__cell>.cell,
  .el-table td.el-table__cell> div
    padding: 0 20px

#app .supervisor-table .el-table .el-table__body-wrapper
  overflow-x: auto
</style>
